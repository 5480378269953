import React, { useState, useRef } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { Fieldset } from 'primereact/fieldset';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';

const Eventos = () => {

    let emptyModel = {
        id: null,
        descricao: '',
        horas: '',
        dsr: 'N',
        tipo: 'P',
        incluiFalta: 'N',
        descontaExtra: 'N',
        absenteismo: 'N',
        acrescentaHora: 'N',
        folgaBH: 'N',
        tipoEvento: 1,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();

    const colunas = [
        { field: 'descricao', header: 'Descrição', headerStyle: { minWidth: '15rem' } },
    ];

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar"
                        mensagemSelecao="Evento Selecionado"
                        camposFiltroGlobal={['descricao']}
                        onModelSelecionado = {onModelSelecionado}
                        life={3000}
                        largura='450px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.descricao}`;
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const onSwitchChange = (e, descricao) => {
        const val = e.value || '';
        let valor = 'N'
        if (val) {
            valor = 'S';
        }
        let _model = { ...model };
        _model[`${descricao}`] = valor;
        setModel(_model);
    }

    const onRadioChange = (e, descricao) => {
        const val = e.value || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const cadastrar = () => {
        if (model.descricao.trim()) {
            if (model.id) {
                api
                    .put("/eventos/" + model.id, {
                        descricao: model.descricao,
                        horas: model.horas,
                        dsr: model.dsr,
                        tipo: model.tipo,
                        incluiFalta: model.incluiFalta,
                        descontaExtra: model.descontaExtra,
                        absenteismo: model.absenteismo === 'N' ? 0 : 1,
                        acrescentaHora: model.acrescentaHora,
                        folgaBH: model.folgaBH,
                        tipoEvento: model.tipoEvento
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_ALTERAR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
            else {
                api
                    .post("/eventos", {
                        descricao: model.descricao,
                        horas: model.horas,
                        dsr: model.dsr,
                        tipo: model.tipo,
                        incluiFalta: model.incluiFalta,
                        descontaExtra: model.descontaExtra,
                        absenteismo: model.absenteismo === 'N' ? 0 : 1,
                        acrescentaHora: model.acrescentaHora,
                        folgaBH: model.folgaBH,
                        tipoEvento: model.tipoEvento
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/eventos/" + model.id)
                .then(() => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }
    }

    const consultar = () => {
        api
            .get("/eventos")
            .then(response => {
                setLista(response.data)
            })
            .catch(erro => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid">
                        <label htmlFor="descricao" className="col-12 mb-2 md:col-1 md:mb-0">Descrição*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="descricao" maxLength={80} placeholder="Máximo 80 caracteres" value={model.descricao} onChange={(e) => onInputChange(e, 'descricao')} required autoFocus className={classNames({ 'p-invalid': submitted && !model.descricao })} />
                            {submitted && !model.descricao && <small className="p-invalid">Descrição Obrigatória</small>}
                        </div>
                    </div>

                    <Fieldset legend="Registro" className='mb-2'>
                        <div className="grid">
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value={1} checked={model.tipoEvento === 1} onChange={(e) => onRadioChange(e, 'tipoEvento')} />
                                    <label htmlFor="option1">Mensalista</label>
                                </div>
                            </div>
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value={3} checked={model.tipoEvento === 3} onChange={(e) => onRadioChange(e, 'tipoEvento')} />
                                    <label htmlFor="option2">Horista</label>
                                </div>
                            </div>
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option3" name="option" value={2} checked={model.tipoEvento === 2} onChange={(e) => onRadioChange(e, 'tipoEvento')} />
                                    <label htmlFor="option3">Banco de Horas</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    <Fieldset legend="Questionário" className='mb-2'>
                        <div className="field grid p-fluid">
                            <label htmlFor="horas" className="col-12 mb-2 md:col-2 md:mb-0">Desconta Horas?</label>
                            <div className="col-12 md:col-10">
                                <InputSwitch checked={model.horas === 'S'} onChange={(e) => onSwitchChange(e, 'horas')} />
                            </div>
                        </div>
                        <div className="field grid p-fluid">
                            <label htmlFor="dsr" className="col-12 mb-2 md:col-2 md:mb-0">Perde DSR?</label>
                            <div className="col-12 md:col-10">
                                <InputSwitch checked={model.dsr === 'S'} onChange={(e) => onSwitchChange(e, 'dsr')} />
                            </div>
                        </div>
                        <div className="field grid p-fluid">
                            <label htmlFor="incluiFalta" className="col-12 mb-2 md:col-2 md:mb-0">Inclui Falta?</label>
                            <div className="col-12 md:col-10">
                                <InputSwitch checked={model.incluiFalta === 'S'} onChange={(e) => onSwitchChange(e, 'incluiFalta')} />
                            </div>
                        </div>
                        <div className="field grid p-fluid">
                            <label htmlFor="descontaExtra" className="col-12 mb-2 md:col-2 md:mb-0">Desconta Extras?</label>
                            <div className="col-12 md:col-10">
                                <InputSwitch checked={model.descontaExtra === 'S'} onChange={(e) => onSwitchChange(e, 'descontaExtra')} />
                            </div>
                        </div>
                        <div className="field grid p-fluid">
                            <label htmlFor="acrescentaHora" className="col-12 mb-2 md:col-2 md:mb-0">Acrescenta Horas Trabalhadas?</label>
                            <div className="col-12 md:col-10">
                                <InputSwitch checked={model.acrescentaHora === 'S'} onChange={(e) => onSwitchChange(e, 'acrescentaHora')} />
                            </div>
                        </div>
                        <div className="field grid p-fluid">
                            <label htmlFor="folgaBH" className="col-12 mb-2 md:col-2 md:mb-0">Folga Banco de Horas?</label>
                            <div className="col-12 md:col-10">
                                <InputSwitch checked={model.folgaBH === 'S'} onChange={(e) => onSwitchChange(e, 'folgaBH')} />
                            </div>
                        </div>
                        <div className="field grid p-fluid">
                            <label htmlFor="absenteismo" className="col-12 mb-2 md:col-2 md:mb-0">Absenteísmo?</label>
                            <div className="col-12 md:col-10">
                                <InputSwitch checked={model.absenteismo === 'S' || model.absenteismo === 1} onChange={(e) => onSwitchChange(e, 'absenteismo')} />
                            </div>
                        </div>
                    </Fieldset>

                    <Fieldset legend="Tipo" className='mb-2'>
                        <div className="grid">
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value="P" checked={model.tipo === 'P'} onChange={(e) => onRadioChange(e, 'tipo')} />
                                    <label htmlFor="option1">Por Período</label>
                                </div>
                            </div>
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value="M" checked={model.tipo === 'M'} onChange={(e) => onRadioChange(e, 'tipo')} />
                                    <label htmlFor="option2">Por Tempo</label>
                                </div>
                            </div>
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option3" name="option" value="MP" checked={model.tipo === 'MP'} onChange={(e) => onRadioChange(e, 'tipo')} />
                                    <label htmlFor="option3">Por Meio Período</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    {/* <div className="field-checkbox">
                        <Checkbox inputId="checkOption1" name="option" value="Absenteísmo" checked={checkboxValue.indexOf('Absenteísmo') !== -1} onChange={onCheckboxChange} />
                        <label htmlFor="checkOption1">Absenteísmo</label>
                    </div> */}

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Eventos, comparisonFn);
