import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const ConfiguracaoExtras = () => {

    let emptyModel = {
        id: 0,
        adnotNormativa: 0,
        adnotIncluiIntervalo: 1,
        mesVigente: 1,
        feriadoInicioJornada: 0,
        mostrarDobraFeriado: 0,
        mostrarColunasExtras: 0,
        retiraResumo: 0,
        verificaPISGrupo: 0,
        compensacaoSemanal: 0,
        horasTrabSemExtras: 0,
        toleranciaEntradaFlexivel: 0,
        redirecinaJornadaRegular: 0,
        converteEmDecimal: 0,
        envioLivreREP: 0,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const colunas = [
        { field: 'dataS', header: 'Data Histórica', headerStyle: { minWidth: '5rem' } },
    ];


    const message = useRef();

    useEffect(() => {

        const buscaHorasExtras = () => {
            api
                .get("/regras/configuracoes/extras")
                .then(response => {
                    if(response.data.length){
                        setModel(response.data[0])
                    }
                    setLista(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        buscaHorasExtras();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const onCheckboxChange = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        let valor = val === 1 ? 0 : 1;
        _model[`${descricao}`] = valor;
        setModel(_model);
    };

    const cadastrar = () => {
        api
            .post("/regras/configuracoes/extras", {
                id: model.id,
                adnotNormativa: model.adnotNormativa,
                adnotIncluiIntervalo: model.adnotIncluiIntervalo,
                mesVigente: model.mesVigente,
                feriadoInicioJornada: model.feriadoInicioJornada,
                mostrarDobraFeriado: model.mostrarDobraFeriado,
                mostrarColunasExtras: model.mostrarColunasExtras,
                retiraResumo: model.retiraResumo,
                verificaPISGrupo: model.verificaPISGrupo,
                compensacaoSemanal: model.compensacaoSemanal,
                horasTrabSemExtras: model.horasTrabSemExtras,
                toleranciaEntradaFlexivel: model.toleranciaEntradaFlexivel,
                redirecinaJornadaRegular: model.redirecinaJornadaRegular,
                converteEmDecimal: model.converteEmDecimal,
                envioLivreREP: model.envioLivreREP,
            })
            .then(response => {
                setLista(response.data)
                addMessage("success", Mensagens.CADASTRO_INCLUIR);
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })

    }

    const selecao = (e) => {
        setModel(e.value);
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Fieldset legend="Adicional Noturno" className='mb-2' toggleable>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption1" name="option" value={model.adnotNormativa} checked={model.adnotNormativa === 1} onChange={(e) => onCheckboxChange(e, "adnotNormativa")} />
                                    <label htmlFor="checkOption1">Calcular por normativa</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption2" name="option" value={model.adnotIncluiIntervalo} checked={model.adnotIncluiIntervalo === 1} onChange={(e) => onCheckboxChange(e, "adnotIncluiIntervalo")} />
                                    <label htmlFor="checkOption2">Incluir intervalo</label>
                                </div>
                            </div>

                        </div>
                    </Fieldset>

                    <Fieldset legend="Horas Extras" className='mb-2' toggleable>
                        <div className="grid">
                            <div className="col-12 md:col-12">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption3" name="option" value={model.feriadoInicioJornada} checked={model.feriadoInicioJornada === 1} onChange={(e) => onCheckboxChange(e, "feriadoInicioJornada")} />
                                    <label htmlFor="checkOption3">Calcular feriado por hora de início de jornada</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    <Fieldset legend="Espelho de Ponto" className='mb-2' toggleable>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption4" name="option" value={model.mostrarDobraFeriado} checked={model.mostrarDobraFeriado === 1} onChange={(e) => onCheckboxChange(e, "mostrarDobraFeriado")} />
                                    <label htmlFor="checkOption4">Mostrar dobra de feriado</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption5" name="option" value={model.toleranciaEntradaFlexivel} checked={model.toleranciaEntradaFlexivel === 1} onChange={(e) => onCheckboxChange(e, "toleranciaEntradaFlexivel")} />
                                    <label htmlFor="checkOption5">Tolerância na entrada flexível</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption6" name="option" value={model.converteEmDecimal} checked={model.converteEmDecimal === 1} onChange={(e) => onCheckboxChange(e, "converteEmDecimal")} />
                                    <label htmlFor="checkOption6">Exibe valores em decimal</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption7" name="option" value={model.mostrarColunasExtras} checked={model.mostrarColunasExtras === 1} onChange={(e) => onCheckboxChange(e, "mostrarColunasExtras")} />
                                    <label htmlFor="checkOption7">Mostrar colunas extras</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption8" name="option" value={model.retiraResumo} checked={model.retiraResumo === 1} onChange={(e) => onCheckboxChange(e, "retiraResumo")} />
                                    <label htmlFor="checkOption8">Não Exibe resumo</label>
                                </div>
                            </div>

                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption11" name="option" value={model.mesVigente} checked={model.mesVigente === 1} onChange={(e) => onCheckboxChange(e, "mesVigente")} />
                                    <label htmlFor="checkOption11">Mês Vigente Espelho</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    <Fieldset legend="Cadastro de colaboradores" className='mb-2' toggleable>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption9" name="option" value={model.verificaPISGrupo} checked={model.verificaPISGrupo === 1} onChange={(e) => onCheckboxChange(e, "verificaPISGrupo")} />
                                    <label htmlFor="checkOption9">Verifica PIS no grupo</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    <Fieldset legend="Tipo de Compensação" className='mb-2' toggleable>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption10" name="option" value={model.compensacaoSemanal} checked={model.compensacaoSemanal === 1} onChange={(e) => onCheckboxChange(e, "compensacaoSemanal")} />
                                    <label htmlFor="checkOption10">Semanal</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    <Fieldset legend="Geral" className='mb-2' toggleable>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption12" name="option" value={model.horasTrabSemExtras} checked={model.horasTrabSemExtras === 1} onChange={(e) => onCheckboxChange(e, "horasTrabSemExtras")} />
                                    <label htmlFor="checkOption12">Horas Trabalhadas Sem Extras</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    <Fieldset legend="Redirecionamento de batidas" className='mb-2' toggleable>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption13" name="option" value={model.redirecinaJornadaRegular} checked={model.redirecinaJornadaRegular === 1} onChange={(e) => onCheckboxChange(e, "redirecinaJornadaRegular")} />
                                    <label htmlFor="checkOption13">Jornada regular</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>

                    <Fieldset legend="Comunicação REP" className='mb-2' toggleable>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption14" name="option" value={model.envioLivreREP} checked={model.envioLivreREP === 1} onChange={(e) => onCheckboxChange(e, "envioLivreREP")} />
                                    <label htmlFor="checkOption14">Envio Livre REP</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>


                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['empresa.fantasia', 'valor', 'inicioS', 'fimS', 'equivalentehoraS']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConfiguracaoExtras, comparisonFn);
