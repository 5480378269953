import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { InputMask } from 'primereact/inputmask';
import { MultiSelect } from 'primereact/multiselect';
import { Fieldset } from 'primereact/fieldset';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';

const Feriados = () => {

    let emptyModel = {
        id: null,
        dataGregoriana: '',
        descricao: '',
        empresas: null,
        unidades: null,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [multiselectValues, setMultiSelectValues] = useState(null);
    const [multiselectUnidadesValues, setMultiSelectUnidadesValues] = useState(null);

    const colunas = [
        { field: 'descricao', header: 'Descrição', headerStyle: { minWidth: '15rem' } },
    ];


    useEffect(() => {

        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => setMultiSelectValues(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaUnidades = () => {
            api
                .get("/unidades")
                .then(response => setMultiSelectUnidadesValues(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
        buscaUnidades();
    }, []);


    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.razao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                {/* <span>{option.name}</span> */}
                <span>{option.fantasia}</span>
            </div>
        );
    };

    const itemTemplateUnidade = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                {/* <span>{option.name}</span> */}
                <span>{option.descricao}</span>
            </div>
        );
    };

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                    <span>{option.fantasia}</span>
                </div>
            );
        }

        return 'Selecione Empresas';
    };

    const selectedItemTemplateUnidade = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                    <span>{option.descricao}</span>
                </div>
            );
        }

        return 'Selecione Unidades';
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar"
                        mensagemSelecao="Feriado Selecionado"
                        camposFiltroGlobal={['descricao']}
                        onModelSelecionado={onModelSelecionado}
                        life={3000}
                        largura='450px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.descricao}`;
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const onSelecao = (e, descricao) => {
        const val = e.value || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const cadastrar = () => {
        if (model.dataGregoriana.trim() && model.descricao.trim()) {
            if (model.id) {
                api
                    .put("/feriados/" + model.id, {
                        descricao: model.descricao,
                        empresas: model.empresas,
                        unidades: model.unidades,
                        dataGregoriana: model.dataGregoriana
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_ALTERAR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
            else {
                api
                    .post("/feriados", {
                        descricao: model.descricao,
                        empresas: model.empresas,
                        unidades: model.unidades,
                        dataGregoriana: model.dataGregoriana
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/feriados/" + model.id)
                .then(() => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(erro => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    const consultar = () => {
        api
            .get("/feriados")
            .then(response => {
                setLista(response.data)
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="dataGregoriana" className="col-12 md:col-1 md:mt-2">Data*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="dataGregoriana" mask="99/99/9999" value={model.dataGregoriana} onChange={(e) => onInputChange(e, 'dataGregoriana')} required className={classNames({ 'p-invalid': submitted && !model.dataGregoriana })} />
                            {submitted && !model.dataGregoriana && <small className="p-invalid">Data Obrigatória</small>}
                        </div>
                        <label htmlFor="descricao" className="col-12 md:col-1 md:mt-2">Descrição*</label>
                        <div className="field col-12 md:col-8 mb-2">
                            <InputText id="descricao" maxLength={30} placeholder="Máximo 30 caracteres" value={model.descricao} onChange={(e) => onInputChange(e, 'descricao')} required className={classNames({ 'p-invalid': submitted && !model.descricao })} />
                            {submitted && !model.descricao && <small className="p-invalid">Descrição Obrigatória</small>}
                        </div>
                    </div>

                    <Fieldset legend="Seleção" className='mb-2'>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12 mb-2">
                                <MultiSelect value={model.empresas} onChange={(e) => onSelecao(e, "empresas")} options={multiselectValues} optionLabel="fantasia" placeholder="Selecione Empresas" filter
                                    itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} maxSelectedLabels={3} />
                            </div>
                        </div>

                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12 mb-2">
                                <MultiSelect value={model.unidades} onChange={(e) => onSelecao(e, "unidades")} options={multiselectUnidadesValues} optionLabel="descricao" placeholder="Selecione Unidades" filter
                                    itemTemplate={itemTemplateUnidade} selectedItemTemplate={selectedItemTemplateUnidade} maxSelectedLabels={3} />
                            </div>
                        </div>
                    </Fieldset>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Feriados, comparisonFn);
