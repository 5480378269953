import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import apiAutenticacao from '../service/apiAutenticacao';
import Mensagens from '../globais/Mensagens';
import { Dialog } from 'primereact/dialog';

export const Login = () => {

	let emptyModel = {
		nomeUsuario: '',
		senha: ''
	};

	const history = useHistory();
	const [submitted, setSubmitted] = useState(false);
	const [model, setModel] = useState(emptyModel);
	const [displayPerdeuSenha, setDisplayPerdeuSenha] = useState(false);
	const message = useRef();

	const goDashboard = () => {
		window.location.href = "/";
	}

	const addMessage = (sv, cn) => {
		message.current.show({ severity: sv, content: cn });
	};

	const cadastrar = () => {
		setSubmitted(true);
		if (model.nomeUsuario.trim() && model.senha.trim()) {
			apiAutenticacao
				.post("/entrar", {
					nomeUsuario: model.nomeUsuario,
					senha: model.senha
				})
				.then(response => {
					if (response.data.token) {
						localStorage.setItem("usuario", JSON.stringify(response.data));
						localStorage.setItem("cliente", JSON.stringify(response.data.cliente));
						goDashboard();
					}
				})
				.catch(erro => {
					if (erro.response) {
						addMessage("error", erro.response.data.message.message);
					} else {
						addMessage("error", Mensagens.ERRO);
					}
				})
			setSubmitted(false)
		}
	}

	const onInputChange = (e, descricao) => {
		const val = (e.target && e.target.value) || '';
		let _model = { ...model };
		_model[`${descricao}`] = val;
		setModel(_model);
	}

	const goError = () => {
		history.push('/error');
	}

	const goRegistro = () => {
		history.push('/usuario/registro');
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			// Executar ação quando a tecla "Enter" for pressionada
			cadastrar();
			// Adicione aqui o código para executar sua ação desejada
		}
	}

	const perdeuSenhaDialogFooter = (
		<>
			<Button type="button" label="Entendi" icon="pi pi-thumbs-up" onClick={() => setDisplayPerdeuSenha(false)} className="p-button-text" autoFocus />
		</>
	)

	return (
		<div className="login-body">
			<div className="body-container">
				<div className="grid">
					<div className="col-12 lg:col-6 left-side">
						<img src="assets/layout/images/logo-white-he.png" alt="apollo-layout" className="logo" />
						<h1>Bem-vindo</h1>
						<p>
							Faça login para iniciar sua sessão
						</p>
					</div>
					<div className="col-12 lg:col-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
								<span className="title">Login</span>

								<div className="grid p-fluid">
									<div className="col-12">
										<InputText placeholder="Email ou Nome de usuário" onChange={(e) => onInputChange(e, 'nomeUsuario')} required autoFocus className={classNames({ 'p-invalid': submitted && !model.nomeUsuario })} />
										{submitted && !model.nomeUsuario && <small className="p-invalid">Email ou usuário obrigatório</small>}

									</div>
									<div className="col-12">
										<Password placeholder="Senha" toggleMask feedback={false} onChange={(e) => onInputChange(e, 'senha')} onKeyDown={handleKeyDown} required className={classNames({ 'p-invalid': submitted && !model.senha })} />
										{submitted && !model.senha && <small className="p-invalid">Senha obrigatória</small>}
									</div>
									<div className="col-6">
										<Button label="Entrar" icon="pi pi-check" onClick={cadastrar}
										/>
									</div>
									<div className="col-6 password-container">
										<button type="text" className="p-link" onClick={(e) => setDisplayPerdeuSenha(true)}>Perdeu a Senha?</button>
									</div>
									<div className="col-12">
										<Messages ref={message} />
									</div>
									<div className="col-12">
										<Button label="Criar minha conta grátis!" className="p-button-warning mr-2 mt-5 mb-2" onClick={goRegistro}
										/>
									</div>

									<Dialog header="Perdeu a senha?" visible={displayPerdeuSenha} onHide={() => setDisplayPerdeuSenha(false)} style={{ width: '350px' }} modal footer={perdeuSenhaDialogFooter}>
										<div className="flex align-items-center justify-content-center">
											<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
											<span>Entre em contato com nosso suporte: (66) 3424-2666.</span>
										</div>
									</Dialog>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
