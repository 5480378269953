import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { MultiSelect } from 'primereact/multiselect';
import Botoes from '../globais/Botoes';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';
import TipoDispositivoEnum from '../utilities/TipoDispositivoEnum';
import { cpf } from 'cpf-cnpj-validator';


const LeitorFacialPermissao = () => {

    let emptyModel = {
        empresa: { id: 0, fantasia: '' },
        departamento: { id: 0, descricao: 'TODOS' },
        cargo: { id: 0, descricao: 'TODOS' },
        colaborador: null,
        uuid: "",
        cpfResponsavel: "",
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [modelSelecionado, setModelSelecionado] = useState(emptyModel);
    const [listaColaboradores, setListaColaboradores] = useState(null);
    const [listaEquipamentos, setListaEquipamentos] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [multiselectValue, setMultiselectValue] = useState([]);
    const [multiselectValueEquipamentos, setMultiselectValueEquipamentos] = useState([]);
    const message = useRef();
    const messageAviso = useRef();
    const messageDialog = useRef();
    const messageInativarDialog = useRef();
    const [value, setValue] = useState(0);
    const interval = useRef(null);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueDepartamento, setAutoFilteredValueDepartamento] = useState([]);
    const [autoValueDepartamento, setAutoValueDepartamento] = useState(null);
    const [autoFilteredValueCargo, setAutoFilteredValueCargo] = useState([]);
    const [autoValueCargo, setAutoValueCargo] = useState(null);
    const [lista, setLista] = useState(null);
    const [dialogVisivel, setDialogVisivel] = useState(false);
    const [cpfValido, setCpfValido] = useState(false);

    const [dialogInativarVisivel, setDialogInativarVisivel] = useState(false);
    const [cpfInativar, setCpfInativar] = useState("");
    const [cpfInvalido, setCpfInvalido] = useState(false);


    const colunas = [
        { field: 'colaborador.nome', header: 'Colaborador', headerStyle: { minWidth: '15rem' } },
        { field: 'uuid', header: 'Link', headerStyle: { minWidth: '30rem' } },
        { field: 'visaoEquipamentos', header: 'Equipamentos', headerStyle: { minWidth: '30rem' } },
    ];

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                //fazer a busca em CADASTROREP E PEGAR TODAS AS EMPRESAS COM LEITOR FACIAL...
                .get("/regras/configuracoes/cadastrorep/empresas?tipoDispositivo="+TipoDispositivoEnum.LEITORFACIAL)
                .then(response => {
                    addMessageAviso("warn", Mensagens.AVISO_EMPRESA_LEITOR_FACIAL)
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscaColaboradores(response.data[0], model.departamento, model.cargo);
                    buscaEquipamentos(response.data[0]);
                    buscaLeitorFacial(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaDepartamentos = () => {
            api
                .get("/departamentos")
                .then(response => setAutoValueDepartamento(response.data)
                )
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaCargos = () => {
            api
                .get("/cargos")
                .then(response => setAutoValueCargo(response.data)
                )
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
        buscaDepartamentos();
        buscaCargos();
    }, []);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 10) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 5000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            }
        }
    }, [value, submitted]);

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.nome}</span>
            </div>
        );
    };

    const itemTemplateEquipamentos = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.nome}</span>
            </div>
        );
    };

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                    <span>{option.nome}</span>
                </div>
            );
        }
        return 'Selecione colaboradores';
    };

    const selectedItemTemplateEquipamentos = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                    <span>{option.nome}</span>
                </div>
            );
        }
        return 'Selecione equipamentos';
    };

    const selecao = (e) => {
        setModelSelecionado(e.value);
        setDialogVisivel(true);
    }

    const buscaColaboradores = (empresa, departamento, cargo) => {
        if (empresa !== null) {
            api
                .get("/colaboradores?empresa=" + empresa.id + "&departamento=" + departamento.id + "&cargo=" + cargo.id)
                .then(response => {
                    let listaColaboradoresCPF = [];
                    for (let i = 0; i < response.data.length; i++) {
                        const element = response.data[i];
                        if (element.cpf !== '') {
                            listaColaboradoresCPF.push(element);
                        }
                    }
                    addMessageAviso("warn", Mensagens.AVISO_COLABORADORES_CPF)
                    setListaColaboradores(listaColaboradoresCPF);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    };

    const buscaEquipamentos = (empresa) => {
        if (empresa !== null) {
            api
                .get("/regras/configuracoes/cadastrorep?empresa=" + empresa.id+"&tipoDispositivo="+TipoDispositivoEnum.LEITORFACIAL )
                .then(response => {
                    setListaEquipamentos(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
        if (descricao === 'empresa' || descricao === 'departamento' || descricao === 'cargo') {
            setMultiselectValue([]);
            setMultiselectValueEquipamentos([]);
            buscaColaboradores(_model.empresa, _model.departamento, _model.cargo);
            buscaEquipamentos(_model.empresa);
            if (descricao === 'empresa') {
                buscaLeitorFacial(_model.empresa);
            }
        }

    }

    const validateCpf = (num) => {
            setCpfValido(cpf.isValid(num));
    }

    const onHideDialog = () => {
        setDialogVisivel(false);
    }

    const onAtualizarSenha = () => {
        if (modelSelecionado.id) {
            api
                .put("/leitorFacial/permissoes/" + modelSelecionado.id + "/limparSenha", {
                    id: modelSelecionado.id,
                })
                .then(() => {
                    addMessageDialog("success", Mensagens.OPERACAO_SUCESSO);
                })
                .catch(() => {
                    addMessageDialog("error", Mensagens.ERRO);
                })
        } else {
            addMessageDialog("warn", Mensagens.SELECIONE);
        }
    }

    const renderFooterDialog = () => {
        return (
            <div>
                <Button label='Atualizar Senha' icon='pi pi-refresh' className="p-button-warning mr-2 mt-1 mb-1" onClick={onAtualizarSenha}></Button>
                {/* <BTInativar inativar={removerPermissao} /> */}
                <Button label="Inativar" icon="pi pi-trash" className="p-button-danger mr-2 mt-1 mb-1" onClick={abrirDialogInativar} disabled={!modelSelecionado || !modelSelecionado.id} />
                <Messages ref={messageDialog}></Messages>
            </div>
        );
    }

    const renderFooterInativarDialog = () => {
        return (
            <div>
                <Button label='Cancelar' icon='pi pi-times' className="p-button-warning mr-2 mt-1 mb-1" onClick={() => setDialogInativarVisivel(false)}></Button>
                <Button label="Autorizar" icon="pi pi-check" className="p-button-success mr-2 mt-1 mb-1" onClick={removerPermissao}/>
                <Messages ref={messageInativarDialog}></Messages>
            </div>
        );
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarDepartamentos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueDepartamento([...autoValueDepartamento]);
                }
                else {
                    setAutoFilteredValueDepartamento(autoValueDepartamento.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarCargos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueCargo([...autoValueCargo]);
                }
                else {
                    setAutoFilteredValueCargo(autoValueCargo.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={Botoes.PERMITIR} icon="pi pi-check" onClick={permitir} className="p-button-success mr-2 mt-1 mb-1" />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const permitir = () => {
        setSubmitted(true);
        if (multiselectValue.length > 0 && multiselectValueEquipamentos.length > 0){
            if(cpfValido){
                setValue(3);
            api
                .post("/leitorFacial/permissoes", {
                    colaboradores: multiselectValue,
                    equipamentos: multiselectValueEquipamentos,
                    empresa: model.empresa,
                    cpfResponsavel: model.cpfResponsavel,
                })
                .then(() => {
                    buscaLeitorFacial(model.empresa);
                    setSubmitted(false);
                    setValue(100);
                    addMessage("success", Mensagens.OPERACAO_SUCESSO);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                    setSubmitted(false);
                    setValue(0);
                })
            }
        } else {
            setSubmitted(false);
            setValue(0);
            addMessage("warn", Mensagens.SELECIONE);
        }
    }

    // const removerPermissao = () => {
    //     if (modelSelecionado.id) {
    //         if(cpfValido){
    //             setSubmitted(true);
    //         api
    //             .delete(`/leitorFacial/permissoes/${modelSelecionado.id}`, {
    //             data: { cpfResponsavel: model.cpfResponsavel },
    //             })
    //             .then(() => {
    //                 addMessage("success", Mensagens.CADASTRO_INATIVAR);
    //                 setSubmitted(false);
    //                 buscaLeitorFacial(model.empresa);
    //                 setModelSelecionado(emptyModel);
    //                 setDialogVisivel(false);
    //             })
    //             .catch(() => {
    //                 addMessage("error", Mensagens.ERRO);
    //             })
    //         }
    //     } else {
    //         addMessage("warn", Mensagens.SELECIONE);
    //     }
    // }

    const removerPermissao = async () => {
        if (!cpfInativar || cpfInvalido) {
            addMessageInativarDialog("warn", "CPF é obrigatório e deve ser válido.");
            return;
        }

        try {
            const payload = {
                ...modelSelecionado,
                cpfResponsavel: cpfInativar, // Adiciona o CPF ao payload
            };

            await api.delete(`/leitorFacial/permissoes/${modelSelecionado.id}`, { data: payload });
            addMessage("success", Mensagens.CADASTRO_INATIVAR);
            buscaLeitorFacial(model.empresa);
            setDialogInativarVisivel(false);
            setDialogVisivel(false);
        } catch {
            addMessageInativarDialog("error", "Erro ao remover permissão.");
        }
    };

    const buscaLeitorFacial = (empresa) => {
        api
            .get("/leitorFacial/permissoes?empresa=" + empresa.id)
            .then(response => setLista(response.data))
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const addMessageAviso = (sv, cn) => {
        messageAviso.current.show({ severity: sv, summary: "Avisos", content: cn, life: 5000 });
    };

    const addMessageDialog = (sv, cn) => {
        messageDialog.current.show({ severity: sv, content: cn });
    };

    const addMessageInativarDialog = (sv, cn) => {
        messageInativarDialog.current.show({ severity: sv, content: cn });
    };

    const abrirDialogInativar = () => {
        setCpfInativar(""); // Limpa o CPF anterior
        setCpfInvalido(false); // Reseta a validação
        setDialogInativarVisivel(true);
    };

    const handleCpfInativarChange = (e) => {
        const cpfValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
        setCpfInativar(cpfValue);
        setCpfInvalido(!cpf.isValid(cpfValue)); // Validação em tempo real
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Messages ref={messageAviso}></Messages>
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="departamento" className="col-12 md:col-1 md:mb-0">Departamento</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="departamento" dropdown value={model.departamento} onChange={(e) => onInputChange(e, 'departamento')} suggestions={autoFilteredValueDepartamento} completeMethod={procurarDepartamentos} field="descricao" />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="cargo" className="col-12 md:col-1 md:mb-0">Cargo</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="cargo" dropdown value={model.cargo} onChange={(e) => onInputChange(e, 'cargo')} suggestions={autoFilteredValueCargo} completeMethod={procurarCargos} field="descricao" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12 mb-2">
                            <MultiSelect value={multiselectValue} onChange={(e) => setMultiselectValue(e.value)} options={listaColaboradores} optionLabel="nome" filter
                                itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} maxSelectedLabels={5} />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12 mb-2">
                            <MultiSelect value={multiselectValueEquipamentos} onChange={(e) => setMultiselectValueEquipamentos(e.value)} options={listaEquipamentos} optionLabel="nome" filter
                                itemTemplate={itemTemplateEquipamentos} selectedItemTemplate={selectedItemTemplateEquipamentos} maxSelectedLabels={5} />
                        </div>
                    </div>

                     <div className="field grid p-fluid">
                       <label htmlFor="cpfResponsavel" className="col-12 md:col-1 md:mt-2">CPF Responsável*</label>
                        <div className="field col-12 md:col-2 mb-2">
                                <InputText id="cpfResponsavel" maxLength={11} placeholder="Máximo 11 caracteres" value={model.cpfResponsavel} onBlur={(e) => validateCpf(e.target.value)} onChange={(e) => onInputChange(e, 'cpfResponsavel')} required className={classNames({ 'p-invalid': submitted && !cpfValido })} />
                                {submitted && !cpfValido && <small className="p-invalid">CPF inválido.</small>}
                        </div>
                    </div>

                    <div className="col-12 ">
                        <Toast ref={toast} />
                        <Toolbar className="mb-0" left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <div className="col-12">
                        <div className="col">
                            <ProgressBar value={value} />
                        </div>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['colaborador.nome', 'visaoEquipamentos']}
                        colunas={colunas}
                    />

                    <Dialog header="Link" visible={dialogVisivel} onHide={() => onHideDialog()} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderFooterDialog()}>
                        <div className="col-12">
                            <label htmlFor="nome" className="md-0">{modelSelecionado.colaborador?.nome.toUpperCase()} </label>
                        </div>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12 mb-2">
                                <InputText id="link" value={modelSelecionado.uuid} />
                            </div>
                        </div>

                    </Dialog>

                    <Dialog header="Inativar Permissão" visible={dialogInativarVisivel} style={{ width: "30vw" }}onHide={() => setDialogInativarVisivel(false)} footer={renderFooterInativarDialog()}>
                            <div className="p-fluid formgrid grid">
                                    <label htmlFor="cpfResponsavel" className="col-12 md:col-3 md:mt-0">CPF Responsável*</label>
                                    <div className="field col-12 md:col-9 mb-2">
                                            <InputText id="cpfResponsavel" maxLength={11} placeholder="Máximo 11 caracteres" value={cpfInativar} onChange={handleCpfInativarChange} required className={cpfInvalido ? "p-invalid" : ""} />
                                            {cpfInvalido && <small className="p-invalid">CPF inválido.</small>}
                                    </div>
                            </div>
                    </Dialog>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(LeitorFacialPermissao, comparisonFn);
